@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3r5u46') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3r5u46') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3r5u46##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close-thin {
  &:before {
    content: "\e933";
  }
}
.icon-tiktok {
  &:before {
    content: $icon-tiktok;
  }
}
.icon-clear-search {
  &:before {
    content: $icon-clear-search; 
  }
}
.icon-slim-search {
  &:before {
    content: $icon-slim-search; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-shopcart {
  &:before {
    content: $icon-shopcart; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-single-square {
  &:before {
    content: $icon-single-square;     
    color: #949494;
  }
}
.icon-grid-icon {
  &:before {
    content: $icon-grid-icon; 
  }
}
.icon-paypal {
  &:before {
    content: $icon-paypal; 
  }
}
.icon-hand-payments {
  &:before {
    content: $icon-hand-payments; 
  }
}
.icon-wishlist {
  &:before {
    content: $icon-wishlist; 
  }
}
.icon-wishlist-full {
  &:before {
    content: $icon-wishlist-full; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-reset {
  &:before {
    content: $icon-reset; 
  }
}
.icon-size-chart-ruler {
  &:before {
    content: $icon-size-chart-ruler; 
  }
}
.icon-accessibility {
  &:before {
    content: $icon-accessibility; 
  }
}
.icon-controls-pause {
  &:before {
    content: $icon-controls-pause; 
  }
}
.icon-controls-play {
  &:before {
    content: $icon-controls-play; 
  }
}
.icon-recycle {
  &:before {
    content: $icon-recycle; 
  }
}
.icon-add-user {
  &:before {
    content: $icon-add-user; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-circle-minus {
  &:before {
    content: $icon-circle-minus; 
  }
}
.icon-circle-plus {
  &:before {
    content: $icon-circle-plus; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-tumblr {
  &:before {
    content: $icon-tumblr; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-googleplus {
  &:before {
    content: $icon-googleplus; 
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-arrow-right-indicator {
  &:before {
    content: $icon-arrow-right-indicator; 
  }
}
.icon-arrow-left-indicator {
  &:before {
    content: $icon-arrow-left-indicator; 
  }
}
.icon-arrow-down-indicator {
  &:before {
    content: $icon-arrow-down-indicator; 
  }
}
.icon-arrow-up-indicator {
  &:before {
    content: $icon-arrow-up-indicator; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-search-old {
  &:before {
    content: $icon-search-old; 
  }
}
.icon-arrow-down-indicator-open {
  &:before {
    content: $icon-arrow-down-indicator-open; 
  }
}
.icon-arrow-up-indicator-open {
  &:before {
    content: $icon-arrow-up-indicator-open; 
  }
}
.icon-arrow-right-indicator-open {
  &:before {
    content: $icon-arrow-right-indicator-open; 
  }
}
.icon-arrow-left-indicator-open {
  &:before {
    content: $icon-arrow-left-indicator-open; 
  }
}
.icon-flickr {
  &:before {
    content: $icon-flickr; 
  }
}
.icon-picasa {
  &:before {
    content: $icon-picasa; 
  }
}
.icon-wordpress {
  &:before {
    content: $icon-wordpress; 
  }
}
.icon-blogger {
  &:before {
    content: $icon-blogger; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-wanelo {
  &:before {
    content: $icon-wanelo; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-menu-old {
  &:before {
    content: $icon-menu-old; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-shrink {
  &:before {
    content: $icon-shrink; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-handset {
  &:before {
    content: $icon-handset; 
  }
}
.icon-address {
  &:before {
    content: $icon-address; 
  }
}
.icon-payment {
  &:before {
    content: $icon-payment; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-sign-out {
  &:before {
    content: $icon-sign-out; 
  }
}
.icon-refer-a-friend {
  &:before {
    content: $icon-refer-a-friend; 
  }
}
.icon-chat {
  &:before {
    content: $icon-chat; 
  }
}
.icon-gift {
  &:before {
    content: $icon-gift; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-applepay {
  &:before {
    content: $icon-applepay; 
  }
}
.icon-enlarge {
  &:before {
    content: $icon-enlarge; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-shopcart-old {
  &:before {
    content: $icon-shopcart-old; 
  }
}
.icon-account {
  &:before {
    content: $icon-account; 
  }
}
.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-hour-glass {
  &:before {
    content: $icon-hour-glass; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-sign-in {
  &:before {
    content: $icon-sign-in; 
  }
}
.icon-success {
  &:before {
    content: $icon-success; 
  }
}
.icon-error {
  &:before {
    content: $icon-error; 
  }
}
.icon-vine {
  &:before {
    content: $icon-vine; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-clock2 {
  &:before {
    content: $icon-clock2; 
  }
}

